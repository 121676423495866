/**
 * Created by LinLiLing on 24/11/21.
 */

// 导入 JSEncrypt 包
import JSEncrypt from 'jsencrypt';

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAl14IjlJQCDoi/rtPm0of
MMJzXv3yjM35SSSI/VM3bvoiigVDK8FpDznZT0sdvrm8Ol2shK9eYb+MLNo3cNXZ
XM2BgPPlm9s0elbMU3bUm/z9dKqrzXKv7xm+J7xu22tK4kFcpP0B/5XfIC73iC7S
40/1tBEJSdCeheYTLu2EisKhfg3NvkYYrQhIcigY+g6GHDGlkMymhHk79Oc7a9o/
eioTOJNKPomBOkRl1yfRnQdyVUvcNkuQ4DCGLy5JTWMq+C0EpkPwHdexv3+DQ0Dj
uzXgvyy8+e5shlcNke4DQBc3Qx0aXCUbRfc+vqXs6sO/THCfvazs8GzIwNcNv5yu
pwIDAQAB
-----END PUBLIC KEY-----`;
/**
 * 加密
 * @param pw
 * @returns {string}
 */
export function encryptPassword(pw) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  const encrypted = encryptor.encrypt(pw);
  return btoa(encrypted); // 使用浏览器内置的 btoa 函数进行 Base64 编码
}